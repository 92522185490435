import { IAlertService } from '../../libs/ialert/ialerts.service'
import { ApiService } from '../../services/api.service'
import { Component, OnInit, ViewChild } from '@angular/core'
import { DataService } from './data.service'
import * as moment from 'moment'
import { ConstantsService } from 'src/app/services/constants.service'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { Router } from '@angular/router'

@Component({
    selector: 'app-driver-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    mt = moment
    month = ''
    year: any = 2020
    todayDate = new Date()
    fetching = true
    visitorFetching = 'inprogress'
    projectCount: any
    totalDownloads = 0
    storedData: any = {}
    dateFormat: any
    monthlySalesData: Array<any> = []
    monthlyTotalAmount = 0
    activeMenu = 'business'
    dataStatus = 'fetching'
    dashboardData: any = []
    topSallingProduct: any = []
    topSallingCategoires: any = []
    recentlyAddedProduct: any = []
    status = false
    mentorDetail :any
    loaderOptions: LoaderOptionsObject = ConstantsService.SKELETON_LOADER_DEFAULT_CONFIG

    constructor(
        public api: ApiService,
        public ds: DataService,
        public cs: ConstantsService,
        public alert: IAlertService,
        public router: Router
    ) {
        this.dateFormat = cs.DATE_TIME_FORMAT.SHORT_DATE
        this.mentorDetail = api.user.mentor
    }

    ngOnInit() {
        this.status = false
        /* this.ds.dashboardData().subscribe((resp: any) => {
            if (resp.success === true) {
                this.dataStatus = 'done'
                this.storedData = resp.data
                this.status = true
            }
        })*/
    }

    logOut(): void {
        this.api.logOutSession().subscribe((resp: any) => {
            const check = this.api.logOut()
            if (check) {
                location.reload()
            }
        })
    }

    openStudents() {
        this.router.navigate(['/admin/students'])
    }

    openTeams() {
        this.router.navigate(['/admin/teams'])
    }

    openTransactions() {
        this.router.navigate(['/admin/transactions'])
    }
}
